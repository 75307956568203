<template>
  <b-field class="is-relative has-fullwidth">
    <b-upload v-model="files" drag-drop multiple class="has-fullwidth">
      <section class="section">
        <div class="content has-text-centered">
          <p>{{ $t("exhibitor_form.exhibitor_attachments_files_hint") }}</p>
        </div>
      </section>
    </b-upload>
    <b-loading v-if="isRequesting" :is-full-page="false" :active="true"></b-loading>
  </b-field>
</template>

<script>
import RequestMixin from "@/shared/mixins/RequestMixin";
import axios from "@/web/services/axios";

export default {
  name: "AttachmentsFileInput",
  mixins: [RequestMixin],

  data() {
    return {
      files: null,
    };
  },

  requests: {
    async createAttachments(filesArray) {
      let promises = filesArray.map(file => {
        const formData = new FormData();
        formData.append("file", file);
        return axios
          .post("attachments", formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then(result => result.data);
      });

      await Promise.all(promises).then(results => {
        this.$emit("input", results);
        this.files = [];
      });
    },
  },

  watch: {
    files: {
      immediate: false,
      handler: function (newValue) {
        if (newValue && newValue.length) {
          this.createAttachments(newValue);
        }
      },
    },
  },
};
</script>

<style scoped></style>
